import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="flex items-center flex-col">
      <h1 className="text-2xl font-bold mb-4 text-gray-900">Oops! Page Not Found</h1>
      <div className="max-w-lg text-gray-900">
        <p>This page does not exist. Click one of the page links in the menu to get back on track.</p>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
